<template>
    <div>
        <div class="tools">
            <div class="tool">
                <select v-model="category">
                    <option value="">All</option>
                    <option :value="category" v-for="(category, index) in categories" :key="index">{{category}}</option>
                </select>
            </div>
            <div class="tool">
                <input type="text" v-model="query" :style="{ backgroundImage : `url('${require('../assets/mag.png')}')`}"/>
            </div>
            <div style="margin-left: auto" class="button" @click="openModal({ modalType: 'add' }); currentFlavor = {}">ADD NEW</div>
        </div>
        <div id="flavors">
            <div class="flavor-entry" v-for="flavor in filteredFlavors" :key="flavor.vqid">
                <span class="vqid-container">
                    <span class="vqid">
                      {{ flavor.vqid }}
                    </span>
                </span> 
                <span class="info">
                    <span>{{ flavor.name }}</span>
                </span>
                <span class="buttons">
                    <div class="button" @click="openModal({ flavor, modalType: 'edit' })">Edit</div> 
                    <div class="button" @click="openModal({ modalType: 'remove', flavor })">Delete</div>
                </span>
            </div>
        </div>
        <transition name="fade">
            <div class="modal" v-if="showModal">
                <div v-if="modalType === 'remove'" class="content confirm">
                    Permanently delete {{ currentFlavor.name }}?
                    <div class="buttons">
                        <div class="button" @click="remove(currentFlavorComputed); showModal = false">YES</div>
                        <div class="button" @click="showModal = false">NO</div>
                    </div>
                </div>
                <div v-if="modalType === 'add'" class="content">
                    <div class="field">
                        Unique ID <input type="number" v-model="currentFlavor.vqid"/>
                    </div>
                    <div class="field">
                        Name <input type="text" v-model="currentFlavor.name"/>
                    </div>
                    <div class="field">
                        Category
                        <select v-model="currentFlavor.category">
                            <option :value="category" v-for="(category, index) in categories" :key="index">{{category}}</option>
                        </select>
                    </div>
                    <div class="field">
                        Description<br/><textarea rows="10" columns="500" v-model="currentFlavor.description"></textarea>
                    </div>
                    <div class="field">
                        VG <input min="0" max="100" type="number" v-model="currentFlavor.VG"/>
                        PG <input min="0" max="100" type="number" v-model="currentFlavorComputed.PG" disabled/>
                    </div>
                    <br/>
                    <div class="buttons">
                        <div v-if="flavors.find(flavor => flavor.vqid === +currentFlavor.vqid)">Unique ID already in use!</div> 
                        <div v-else-if="+currentFlavor.vqid < 101">Unique IDs begin at 101!</div> 
                        <div class="button" v-else @click="create(currentFlavorComputed); showModal = false">Add New</div>
                        <div class="button" @click="showModal = false;">Cancel</div>
                    </div>
                </div>
                <div v-if="modalType === 'edit'" class="content">
                    <span class="vqid-container">
                        <span class="vqid">
                          {{ currentFlavor.vqid }}
                        </span>
                    </span> 
                    <div class="field">
                        Name <input type="text" v-model="currentFlavor.name"/>
                    </div>
                    <div class="field">
                        Category
                        <select v-model="currentFlavor.category">
                            <option :value="category" v-for="(category, index) in categories" :key="index">{{category}}</option>
                        </select>
                    </div>
                    <div class="field">
                        Description <textarea rows="4" columns="50" v-model="currentFlavor.description"></textarea>
                    </div>
                    <div class="field">
                        VG <input min="0" max="100" type="number" v-model="currentFlavor.VG"/>
                        PG <input min="0" max="100" type="number" v-model="currentFlavorComputed.PG" disabled/>
                    </div>
                    <br/>
                    <div class="buttons">
                        <div class="button" @click="update(currentFlavorComputed); showModal = false">Update</div>
                        <div class="button" @click="showModal = false;">Cancel</div>
                    </div>
                </div>
                <div class="overlay" @click="showModal = false"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            showModal: false,
            currentFlavor: {}
        }
    },
    computed: {
        ...mapState('flavors', ['flavors', 'categories']),
        ...mapGetters('flavors', ['filteredFlavors']),
        query: {
            get() { return this.$store.state.flavors.query },
            set(query) { this.$store.commit('flavors/SET_QUERY', query) }
        },
        category: {
            get() { return this.$store.state.flavors.category },
            set(category) { this.$store.commit('flavors/SET_CATEGORY', category) }
        },
        currentFlavorComputed() {
            return { ...this.currentFlavor, PG: 100 - this.currentFlavor.VG }
        }
    },
    methods: {
        ...mapActions('flavors', ['get', 'update', 'create', 'remove']),
        openModal(options) {
            this.modalType = options.modalType
            if (options.flavor) this.currentFlavor = options.flavor
            this.showModal = true
        }
    },
    created() {
        this.get()
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
@import url('https://fonts.googleapis.com/css?family=Srisakdi');

.tools {
    div {
        margin: 10px;
    }
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #97d3ceee;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
        width: auto;
    }
    .tool {
        display: flex;
        flex-direction: column;
        input[type="text"] {
            background-repeat: no-repeat;
            background-size: contain;
            padding-left: 20px;
        }
    }
}

#flavors {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.vqid-container {
    height: 40px;
    min-width: 46px;
    .vqid {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        font-family: Nunito Sans;
        font-weight: bold;
        font-size: 1em;
        background: #cdace4;
        color: white;
        border: 3px solid white;
    }
}

.flavor-entry {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-family: Nunito Sans;
    width: 60%;
    background-image: linear-gradient(to left, #e5e5e5 17%, #ace4df 64%);
    margin: 10px;
    align-items: center;
    padding: 10px;
    span {
    }
    .info {
        width: 100%;
        text-align: center;
        font-size: 1.3em;
    }
}

.buttons {
    display: flex;
}

.button {
    cursor: pointer;
    border: 3px solid white;
    margin: 5px;
    padding: 5px;
    background: #cdace4;
    color: white;
    font-family: Nunito Sans;
    font-weight: bold;
    text-align: center;
    width: 50px;
}

.modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    .overlay {
        background: #000000aa;
        width: 100%;
        height: 100%;
    }
    .content {
        .vqid-container {
            position: absolute;
            top: 10px;
            left: 10px;
        }
        border-radius: 20px;
        box-shadow: 8px 8px 5px 0 #00000055;
        background-image: linear-gradient(to left, #e5e5e5 17%, #ace4df 64%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
        .button {
            width: 100px;
        }
        .field {
            margin: 5px;
            display: flex;
            justify-content: space-between;
            width: 80%;
            textarea {
                width: 80%;
                padding: 10px;
                font-family: Nunito Sans;
                font-size: inherit;
            }
            input[type="text"] {
                width: 80%;
                padding: 10px;
            }
            input[type="number"] {
                padding: 10px;
            }
        }
        &.confirm {
            top: calc(50% - 15%);
            left: calc(50% - 20%);
            width: 40%;
            height: 30%;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}


</style>

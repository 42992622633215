import Api from '../../services/api/index'

let module = {
    state: {
        subscriptions: []
    },
    mutations: {
        SET_SUBSCRIPTIONS(state, val) {
            state.subscriptions = val
        }
    },
    actions: {
        async get({ commit }) {
            let subscriptions = await Api.subscriptions.get()
            commit('SET_SUBSCRIPTIONS', subscriptions)
        }
    }
}

export default {
    ...module,
    namespaced: true
}

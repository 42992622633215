<template>
    <div>
        <div class="tools">
            <div class="tool">
                <select v-model="category">
                    <option value="">All</option>
                    <option :value="category" v-for="(category, index) in categories" :key="index">{{category}}</option>
                </select>
            </div>
            <div class="tool">
                <input type="text" v-model="query" :style="{ backgroundImage : `url('${require('../assets/mag.png')}')`}"/>
            </div>
            <div style="margin-left: auto" class="button" @click="openModal({ modalType: 'add' }); currentAnnouncement = {}">ADD NEW</div>
        </div>
        <div id="flavors">
            <div class="flavor-entry" v-for="announcement in filteredAnnouncements" :key="announcement._id">
                <span class="image-container">
                    <span class="image" @click="currentAnnouncement = announcement; showImageModal = true" :style="{backgroundImage: `url(${announcement.image})`}"></span>
                </span> 
                <span class="info">
                    <span>{{ announcement.title }}</span>
                </span>
                <span class="buttons">
                    <div class="button" @click="openModal({ announcement, modalType: 'edit' })">Edit</div> 
                    <div class="button" @click="openModal({ announcement, modalType: 'remove' })">Delete</div>
                </span>
            </div>
        </div>
        <transition name="fade">
            <div class="image-modal" v-if="showImageModal">
                <img v-if="showImageModal" :src="currentAnnouncement.image"/>
                <div class="overlay" @click="showImageModal = false"></div>
            </div>
            <div class="modal" v-if="showModal">
                <div v-if="modalType === 'remove'" class="content confirm">
                    Permanently delete {{ currentAnnouncement.title }}?
                    <div class="buttons">
                        <div class="button" @click="remove(currentAnnouncement); showModal = false">YES</div>
                        <div class="button" @click="showModal = false">NO</div>
                    </div>
                </div>
                <div v-if="modalType === 'add'" class="content">
                    <div class="field">
                        Title <input type="text" v-model="currentAnnouncement.title"/>
                    </div>
                    <div class="field">
                        Category <input type="text" v-model="currentAnnouncement.category"/>
                    </div>
                    <div class="field">
                        Image URL <input type="text" v-model="currentAnnouncement.image"/>
                    </div>
                    <div class="field">
                        Article Lnk <input type="text" v-model="currentAnnouncement.link"/>
                    </div>
                    <div class="field">
                        Body <br/><textarea rows="10" columns="500" v-model="currentAnnouncement.body"></textarea>
                    </div>
                    <br/>
                    <div class="buttons">
                        <div class="button" @click="create(currentAnnouncement); showModal = false">Add New</div>
                        <div class="button" @click="showModal = false;">Cancel</div>
                    </div>
                </div>
                <div v-if="modalType === 'edit'" class="content">
                    <span class="image-container">
                        <span @click="showImageModal = true" class="image" :style="{backgroundImage: `url(${currentAnnouncement.image})`}">
                        </span>
                    </span> 
                    <div class="field">
                        Title <input type="text" v-model="currentAnnouncement.title"/>
                    </div>
                    <div class="field">
                        Category <input type="text" v-model="currentAnnouncement.category"/>
                    </div>
                    <div class="field">
                        Image URL <input type="text" v-model="currentAnnouncement.image"/>
                    </div>
                    <div class="field">
                        Article Lnk <input type="text" v-model="currentAnnouncement.link"/>
                    </div>
                    <div class="field">
                        body <textarea rows="4" columns="50" v-model="currentAnnouncement.body"></textarea>
                    </div>
                    <br/>
                    <div class="buttons">
                        <div class="button" @click="update(currentAnnouncement); showModal = false">Update</div>
                        <div class="button" @click="showModal = false;">Cancel</div>
                    </div>
                </div>
                <div class="overlay" @click="showModal = false"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            showModal: false,
            showImageModal: false,
            currentAnnouncement: {}
        }
    },
    computed: {
        ...mapState('announcements', ['announcements', 'categories']),
        ...mapGetters('announcements', ['filteredAnnouncements']),
        query: {
            get() { return this.$store.state.announcements.query },
            set(query) { this.$store.commit('announcements/SET_QUERY', query) }
        },
        category: {
            get() { return this.$store.state.announcements.category },
            set(category) { this.$store.commit('announcements/SET_CATEGORY', category) }
        },
    },
    methods: {
        ...mapActions('announcements', ['get', 'update', 'create', 'remove']),
        openModal(options) {
            this.modalType = options.modalType
            if (options.announcement) this.currentAnnouncement = options.announcement
            this.showModal = true
        }
    },
    created() {
        this.get()
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
@import url('https://fonts.googleapis.com/css?family=Srisakdi');

.tools {
    div {
        margin: 10px;
    }
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #97d3ceee;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
        width: auto;
    }
    .tool {
        display: flex;
        flex-direction: column;
        input[type="text"] {
            background-repeat: no-repeat;
            background-size: contain;
            padding-left: 20px;
        }
    }
}

#flavors {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.image-container {
    max-height: 120px;
    min-width: 120px;
    &:hover {
        cursor: pointer;
    }
    .image {
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid white;
    }
}

.flavor-entry {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-family: Nunito Sans;
    width: 80%;
    background-image: linear-gradient(to left, #e5e5e5 17%, #ace4df 64%);
    margin: 10px;
    align-items: center;
    padding: 10px;
    span {
    }
    .info {
        margin-left: 3%;
        font-weight: bold;
        width: 100%;
        text-align: left;
        font-size: 1.3em;
    }
}

.buttons {
    display: flex;
}

.button {
    cursor: pointer;
    border: 3px solid white;
    margin: 5px;
    padding: 5px;
    background: #cdace4;
    color: white;
    font-family: Nunito Sans;
    font-weight: bold;
    text-align: center;
    width: 50px;
}

.image-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3;
    .overlay {
        background: #000000aa;
        width: 100%;
        height: 100%;
    }
    img {
        top: 50%;
        left: 50%;
        min-width:60%;
        max-width:60%;
        position: fixed;
        border-radius: 20px;
        transform: translate(-50%, -50%);
    }
    .image-full {
        box-shadow: 8px 8px 5px 0 #00000055;
        background-image: linear-gradient(to left, #e5e5e5 17%, #ace4df 64%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
    }
}

.modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    .overlay {
        background: #000000aa;
        width: 100%;
        height: 100%;
    }
    .content {
        .image-container {
            position: absolute;
            top: 30px;
            left: 30px;
        }
        border-radius: 20px;
        box-shadow: 8px 8px 5px 0 #00000055;
        background-image: linear-gradient(to left, #e5e5e5 17%, #ace4df 64%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
        .button {
            width: 100px;
        }
        .field {
            margin: 5px;
            display: flex;
            justify-content: space-between;
            width: 80%;
            textarea {
                width: 80%;
                padding: 10px;
                font-family: Nunito Sans;
                font-size: inherit;
            }
            input[type="text"] {
                width: 80%;
                padding: 10px;
            }
            input[type="number"] {
                padding: 10px;
            }
        }
        &.confirm {
            top: calc(50% - 15%);
            left: calc(50% - 20%);
            width: 40%;
            height: 30%;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.image-container {
        height: 15rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
}

</style>

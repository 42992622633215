<template>
  <div class="home">
      DASHBOARD
  </div>
</template>

<script>

export default {
  name: 'home',
}
</script>

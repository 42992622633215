<template>
    <div class="header">
        <nav :class="[scrollTop ? 'top' : 'scrolled']">
            <!-- <div id="brand" :style="{backgroundImage: `url(${require('../assets/VAPEQUEEN-2-.png')})`}"></div> -->
            <div id="toggle" :class="{on: toggleActive}" style="height: 0">
				<div class="expand" @click="toggleActive = !toggleActive">
					<div class="span" id="one"></div>
					<div class="span" id="two"></div>
					<div class="span" id="three"></div>
				</div>
            </div>
            <ul id="menu">
                <span class="nav-link"><router-link tag="li" to="/">Home</router-link> / </span>
                <span class="nav-link"><router-link tag="li" to="/flavors">Flavors</router-link> / </span>
                <span class="nav-link"><router-link tag="li" to="/newsfeed">News Feed</router-link></span>
                <!-- <span class="nav-link"><router-link tag="li" to="/subscriptions">subscriptions</router-link> / </span> -->
                <!-- <span class="nav-link"><router-link tag="li" to="/messages">Messages</router-link></span> -->
            </ul>
            <span v-if="token" @click="logout('Logged out succesfully')" id="logout">LOG&nbsp;OUT</span>
            <span v-else id="logout"></span>

        </nav>

        <div id="resize" :class="{ active: toggleActive }">
            <ul id="menu">
                <router-link tag="li" to="/" @click.native="toggleActive = false"><span>Home</span></router-link>
                <router-link tag="li" to="/flavors" @click.native="toggleActive = false">Flavors</router-link>
                <router-link tag="li" to="/newsfeed" @click.native="toggleActive = false">News Feed</router-link>
                <!-- <router-link tag="li" to="/subscribers" @click.native="toggleActive = false">Subscribers</router-link> --> 
                <!-- <router-link tag="li" to="/messages" @click.native="toggleActive = false">Messages</router-link> -->
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            toggleActive: false,
            scrollTop: true
        }
    },
    computed: {
        ...mapState('authentication', ['token'])
    },
    methods: {
        ...mapActions('authentication', ['logout']),
        scrollHandler() {
            if (window.scrollY < 110) this.scrollTop = true
            else this.scrollTop = false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollHandler)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollHandler)
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

.nav-link {
    font-family: Nunito Sans;
    font-weight: bold;
    font-size: 1.2em;
    color: white;
}

@keyframes slidein {
    from {
        margin-top: -100px;
    }
    to {
        margin-top: 0px;
    }
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    #logout {
        margin-right: 10px;
        font-family: Nunito Sans;
        font-weight: bold;
        font-size: 1.4em;
        color: white;
        transition: all 0.3s ease-out;
        min-width: 10%;
		&:hover {
			color: #d8c6e4;
            cursor: pointer;
		}
    }
    &.top {
        // margin-bottom: 60px;
        background: #97d3ceee;
        z-index: 1;
        width: 100%;
        transition: all 0.5s ease;
        #menu {
            .nav-link {
                color: white;
            }
            li {
                color: white;
            }
        }
    }
    &.scrolled {
        background: #97d3ceee;
        z-index: 1;
        position: fixed;
        width: 100%;
        animation-duration: 0.5s;
        animation-name: slidein;
        top: 0;
        #brand {
            display: none;
        }
    }
    #brand {
        background-size: contain; 
        background-repeat: no-repeat; 
        // background-color: #97d3ceee;
        width: 100px; 
        height: 100px;
        border-radius: 100%;
        &:before {
            background-color: #212121
        }
    }
    #menu {
        padding: 0;
        margin-bottom: 5px;
        li {
            display: inline-block;
            padding: 0px 10px;
            cursor: pointer;
            line-height: 40px;
            position: relative;
            transition: all 0.3s ease-out;
            color: #AAA;
            color: white;
            font-family: Nunito Sans;
            font-size: 1.0em;
			&:hover {
				color: #d8c6e4;
			}
        }
    }
}

nav #menu li.router-link-exact-active {
    color: #black;
}

#toggle {
  z-index: 2;
  height: 40px;
  width: 100%;
  transition: all 0.3s ease-out;
  visibility: hidden;
  display: none !important;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

#toggle .expand {
  cursor: pointer;
  width: 40px;
}

#toggle .span {
  width: 40px;
  height: 7px;
  background: #333;
  margin: 5px auto;
  backface-visibility: hidden;
}

#toggle.on .expand #one {
  transform: rotate(45deg) translateX(6px) translateY(6px);
}

#toggle.on .expand #two {
  opacity: 0;
}

#toggle.on .expand #three {
  transform: rotate(-45deg) translateX(10px) translateY(-12px);
}

#resize {
  font-family: Nunito Sans;
  z-index: 1;
  position: fixed;
  top: 75px;
  right: 0;
  background: #97d3ceee;
  width: 50%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
  display: table;
}

#resize #menu {
  padding-top: 60px;
  height: 50px;
  display: table-cell;
  vertical-align: right;
}

#resize #menu li {
  display: block;
  padding: 20px 0;
  padding-right: 20px;
  text-align: right;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

#resize #menu li:hover {
  background: #212121;
}

#resize #menu li:hover a {
  color: #fff;
  transition: all 0.3s ease-out;
}

#resize #menu li a {
  color: #212121;
}

#resize.active {
  visibility: visible;
  opacity: 0.99;
}

@media (max-width: 768px) {
  #toggle {
    visibility: visible;
    display: flex !important;
	height: 40px !important;
    opacity: 1;
  }
  #brand {
      display: none;
  }
  nav.top {
      align-items: center;
      height: 75px;
  }
  nav #menu {
    display: none;
  }
}

@media (min-width: 768px) {
  #resize {
    visibility: hidden !important;
  }
}
</style>

import Api from '../../services/api/index'

let module = {
    state: {
        announcements: [],
        categories: [],
        category: '',
        query: ''
    },
    mutations: {
        SET_ANNOUNCEMENTS(state, val) {
            state.announcements = val
        },
        SET_QUERY(state, val) {
            state.query = val
        },
        SET_CATEGORY(state, val) {
            state.category = val
        },
        SET_CATEGORIES(state, val) {
            state.categories = val
        }
    },
    getters: {
        filteredAnnouncements: state => { 
            return state.announcements.filter(announcement => { 
                return new RegExp(`${state.query}`, 'i')
                    .test(announcement.title) && ( !state.category ? true : state.category === announcement.category )
            })
        }
    },
    actions: {
        async get({ commit }) {
            let announcements = await Api.announcements.get()
            let categories = []
            commit('SET_ANNOUNCEMENTS', announcements.sort((a, b) => a.publish_date - b.publish_date))
            for (let announcement of announcements) {
                if (categories.indexOf(announcement.category) === -1) categories.push(announcement.category)
            }
            console.log(categories)
            commit('SET_CATEGORIES', categories)
        },
        async update({ dispatch }, announcement) {
            await Api.announcements.update(announcement)
            dispatch('get')
        },
        async create({ dispatch }, announcement) {
            console.log(announcement)
            await Api.announcements.create(announcement)
            dispatch('get')
        },
        async remove({ dispatch }, announcement) {
            await Api.announcements.delete(announcement)
            dispatch('get')
        }
    },
}

export default {
    ...module,
    namespaced: true
}

import flavors from './flavors'
import subscriptions from './subscriptions'
import announcements from './announcements'
import authentication from './authentication'

export default {
    flavors,
    subscriptions,
    announcements,
    authentication
}

import api from './api.js'

export default {
    get: () => api.get('/flavors').then(res => res.data).catch(e => console.log(e)),
    update: (flavor) => 
        api.put('/flavors', { flavor })
            .then(res => res.data)
            .catch(e => console.log(e)),
    create: (flavor) =>
        api.post('/flavors', { flavor })
            .then(res => res.data)
            .catch(e => console.log(e)),
    delete: (flavor) =>
        api.delete('/flavors', { params: { vqid: flavor.vqid }})
            .then(res => res.data)
            .catch(e => console.log(e))
}

import axios from 'axios'
import store from '../../store/index'


let api = axios.create({
    baseURL: 'https://api.thevapequeen.com'
})

const token = localStorage.getItem('token')
if (token) api.defaults.headers.common['Authorization'] = token

api.interceptors.response.use(null, error => {
    console.log(error)
    if (error.response.status == 401) {
        console.log(error)
        store.dispatch('authentication/logout', 'Session expired, please sign in.')
    }
})

export default api

<template>

    <span>
    <form method="post" @submit.prevent="login">
        <div class="box">
            <h1>Dashboard</h1>
            <input class="email" required v-model="email" type="email" placeholder="Enter your email" />
            <br/>
            <br/>
            <input class="password" required v-model="password" type="password" placeholder="Enter your password" />
            <div class="btn" @click="login">Sign In</div>
        </div>
    </form>
    <br/>
    <span class="error" v-if="error">{{ error }}</span>

    <!--
    <div class="login">
        <form id="login" @submit.prevent="login">
            <h1>Sign In</h1>
            <label>Email </label>
            <input required v-model="email" type="email" placeholder="Enter your email" />
            <br/>
            <label>Password </label>
            <input required v-model="password" type="password" placeholder="Enter your password" />
            <br/>
            <button type="submit">Sign In</button>
            <h2 v-if="error">{{ error }}</h2>
        </form>
    </div>
    -->
    </span>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            email: "",
            password: ""
        }
    },
    computed: {
        ...mapState('authentication', ['error']),
    },
    methods: {
        ...mapActions('authentication', ['authenticate']),
        login() {
            if (this.email && this.password) {
                this.authenticate({ email: this.email, password: this.password })
                    .then(() => this.$router.push('/flavors'))
                    .catch(err => console.log(err))
            }
        }
    }
}

</script>

<style>
body{
  font-family: 'Open Sans', sans-serif;
  margin: 0 auto 0 auto;
  width:100%;
  text-align:center;
}

p{
  font-size:12px;
  text-decoration: none;
  color:#ffffff;
}

h1{
  font-size:1.5em;
  color:#525252;
}

.error {
    font-size: 1.4em;

}

.box{
  background:white;
  width:300px;
  border-radius:6px;
  margin: 30px auto 0 auto;
  padding:0px 0px 70px 0px;
  border: #2980b9 4px solid;
  box-shadow: 10px 10px 5px #aaa;
}

.email{
  background:#ecf0f1;
  border: #ccc 1px solid;
  border-bottom: #ccc 2px solid;
  padding: 8px;
  width:250px;
  color:#AAAAAA;
  margin-top:10px;
  font-size:1em;
  border-radius:4px;
}

.password{
  border-radius:4px;
  background:#ecf0f1;
  border: #ccc 1px solid;
  padding: 8px;
  width:250px;
  font-size:1em;
}

.btn{
  background:#2ecc71;
  width:125px;
  padding-top:5px;
  padding-bottom:5px;
  color:white;
  border-radius:4px;
  border: #27ae60 1px solid;

  margin-top:20px;
  margin-bottom:20px;
  float:left;
  margin-left:30%;
  font-weight:800;
  font-size:0.8em;
}

.btn:hover{
  background:#2CC06B;
  cursor: pointer;
}

#btn2{
  float:left;
  background:#3498db;
  width:125px;  padding-top:5px;
  padding-bottom:5px;
  color:white;
  border-radius:4px;
  border: #2980b9 1px solid;

  margin-top:20px;
  margin-bottom:20px;
  margin-left:10px;
  font-weight:800;
  font-size:0.8em;
}

#btn2:hover{
background:#3594D2;
}
</style>

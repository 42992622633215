import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'
import Home from './views/Home.vue'
import Flavors from './views/Flavors.vue'
import Subscriptions from './views/Subscriptions.vue'
import Announcements from './views/Announcements.vue'
import Login from './views/Login.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/flavors',
        name: 'flavors',
        component: Flavors,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: Subscriptions,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/newsfeed',
        name: 'newsfeed',
        component: Announcements,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    }
  ]
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        console.log(store.getters['authentication/authorized'])
        if (store.getters['authentication/authorized']) {
            next()
            return
        }
        // store.commit('authentication/SET_ERROR', 'You must sign in for access!')
        next('/login')
    } else {
        next()
    }
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'
import flavors from './modules/flavors'
import subscriptions from './modules/subscriptions'
import announcements from './modules/announcements'
import authentication from './modules/authentication'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        flavors,
        subscriptions,
        announcements,
        authentication
    }
})

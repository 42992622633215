import Api from '../../services/api/index'
import axios from '../../services/api/api'
import router from '../../router'

let module = {
    state: {
        error: '',
        token: localStorage.getItem('token') || null
    },
    mutations: {
        SET_TOKEN(state, val) {
            if (val) {
                localStorage.setItem('token', val)
                state.token = val
                axios.defaults.headers.common['Authorization'] = val
            } else {
                localStorage.removeItem('token')
                state.token = val
                delete axios.defaults.headers.common['Authorization']
            }
        },
        SET_ERROR(state, val) {
            state.error = val
        },
    },
    getters: {
        authorized: state => {
            return !!state.token
        }
    },
    actions: {
        async authenticate({commit}, user) {
            let authResult = await Api.authentication.authenticate(user)
            commit('SET_TOKEN', authResult.token)
            commit('SET_ERROR', authResult.error) 
            router.push('/')
        },
        async logout({commit}, error = null) {
            commit('SET_TOKEN', null)
            commit('SET_ERROR', error)
            router.push('/login')
        }
    },
}

export default {
    ...module,
    namespaced: true
}

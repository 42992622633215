import api from './api.js'

export default {
    get: () => 
        api.get('/announcements')
            .then(res => res.data).catch(e => console.log(e)),

    update: (announcement) => 
        api.put('/announcements', { announcement })
            .then(res => res.data)
            .catch(e => console.log(e)),

    create: (announcement) =>
        api.post('/announcements', { announcement })
            .then(res => res.data)
            .catch(e => console.log(e)),

    delete: (announcement) =>
        api.delete('/announcements', { params: { _id: announcement._id }})
            .then(res => res.data)
            .catch(e => console.log(e))
}

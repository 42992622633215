import Api from '../../services/api/index'

let module = {
    state: {
        categories: [],
        flavors: [],
        category: '',
        query: ''
    },
    mutations: {
        SET_FLAVORS(state, val) {
            state.flavors = val
        },
        SET_QUERY(state, val) {
            state.query = val
        },
        SET_CATEGORY(state, val) {
            state.category = val
        },
        SET_CATEGORIES(state, val) {
            state.categories = val
        }
    },
    getters: {
        filteredFlavors: state => { 
            return state.flavors.filter(flavor => { 
                return new RegExp(`${state.query}`, 'i').test(flavor.name) && ( !state.category ? true : state.category === flavor.category )
            })
        }
    },
    actions: {
        async get({ commit }) {
            let flavors = await Api.flavors.get()
            let categories = []
            commit('SET_FLAVORS', flavors.sort((a, b) => a.vqid - b.vqid))
            for (let flavor of flavors) {
                if (categories.indexOf(flavor.category) === -1) categories.push(flavor.category)
            }
            commit('SET_CATEGORIES', categories)
        },
        async update({ dispatch }, flavor) {
            await Api.flavors.update(flavor)
            dispatch('get')
        },
        async create({ dispatch }, flavor) {
            console.log(flavor)
            await Api.flavors.create(flavor)
            dispatch('get')
        },
        async remove({ dispatch }, flavor) {
            await Api.flavors.delete(flavor)
            dispatch('get')
        }
    },
}

export default {
    ...module,
    namespaced: true
}

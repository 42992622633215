<template>
    <span>
        <div v-for="(subscription, index) in subscriptions" :key="index">
            {{ subscription.email }}
        </div>
    </span>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    computed: {
        ...mapState('subscriptions', ['subscriptions'])
    },
    methods: {
        ...mapActions('subscriptions', ['get'])
    },
    created() {
        this.get()
    }
}
</script>
<style lang="scss"></style>
